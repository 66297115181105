import React from 'react';
import SbEditable from 'storyblok-react';
import { graphql, useStaticQuery } from 'gatsby';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { PhoneNumber } from '@sporkbytes/material-ui-kit-react';

import Link from 'components/Link';

const useStyles = makeStyles(theme => ({
	contactMethod: {
		fontWeight: ({ hasMultipleLocations }) =>
			hasMultipleLocations ? 'normal' : 'bold',
	},
	headline: {
		marginBottom: theme.spacing(4),
		textAlign: 'center',
	},
	subheadline: {
		margin: theme.spacing(2, 0),
	},
}));

const ContactInformation = ({ blok }) => {
	const data = useStaticQuery(graphql`
		query GetContactInformation {
			allSporkLocation {
				nodes {
					id
					name
					streetAddress
					streetAddress2
					city
					state
					zipCode
					phoneNumber
					emailAddress
				}
			}
		}
	`);
	const hasMultipleLocations = data.allSporkLocation.nodes.length > 1;
	const classes = useStyles({ hasMultipleLocations });

	const ContactMethod = ({ children }) => (
		<Typography component="span" className={classes.contactMethod}>
			{children}
		</Typography>
	);
	const Subheadline = ({ children }) => (
		<Typography variant="h4" component="h3" className={classes.subheadline}>
			{children}
		</Typography>
	);

	return (
		<SbEditable content={blok}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography
						variant="h2"
						component="h1"
						className={classes.headline}
					>
						{blok.headline}
					</Typography>
					<Typography>{blok.leadInText}</Typography>
					{blok.chatText && hasMultipleLocations ? (
						<>
							<Subheadline>Chat</Subheadline>
							<Typography>{blok.chatText}</Typography>
						</>
					) : null}
					{data.allSporkLocation.nodes.map(location => {
						const fullAddress = `${location.streetAddress}${
							location.streetAddress2
								? ' ' + location.streetAddress2
								: ''
						}, ${location.city}, ${location.state} ${
							location.zipCode
						}`;

						return (
							<>
								{hasMultipleLocations ? (
									<Subheadline>{location.name}</Subheadline>
								) : null}
								<ul>
									{blok.chatText && !hasMultipleLocations ? (
										<li>
											<ContactMethod>Chat</ContactMethod>{' '}
											- {blok.chatText}
										</li>
									) : null}
									<li>
										<ContactMethod>Phone</ContactMethod> -{' '}
										<PhoneNumber
											value={location.phoneNumber}
											renderText={value => (
												<Link
													href={`tel:${location.phoneNumber}`}
												>
													{value}
												</Link>
											)}
										/>
									</li>
									<li>
										<ContactMethod>Email</ContactMethod> -{' '}
										<Link
											href={`mailto:${location.emailAddress}`}
											target="_blank"
											rel="noopener noreferrer"
										>
											{location.emailAddress}
										</Link>
									</li>
									<li>
										<ContactMethod>Office</ContactMethod> -{' '}
										<Link
											href={`https://www.google.com/maps/dir//${fullAddress}`}
											target="_blank"
											rel="noopener noreferrer"
										>
											{fullAddress}
										</Link>
									</li>
								</ul>
							</>
						);
					})}
				</Grid>
			</Grid>
		</SbEditable>
	);
};

export default ContactInformation;
