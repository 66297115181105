import React from 'react';
import SbEditable from 'storyblok-react';
import { Grid, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	amount: {
		color: theme.palette.primary[500],
		fontSize: theme.typography.h1.fontSize,
		margin: theme.spacing(2, 0),
		textAlign: 'center',
	},
	description: {
		fontSize: theme.typography.h2.fontSize,
		fontWeight: 'normal',
		textAlign: 'center',
	},
}));

const Metric = ({ blok }) => {
	const classes = useStyles();

	return (
		<SbEditable content={blok}>
			<Grid container justify="center" direction="column">
				<Typography component="h2" className={classes.amount}>
					{blok.amount}
				</Typography>
				<Typography
					variant="p"
					component="h3"
					className={classes.description}
				>
					{blok.description}
				</Typography>
			</Grid>
		</SbEditable>
	);
};

export default Metric;
