import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const selectorHeight = 16;
const useStyles = makeStyles(theme => ({
	container: {
		flexGrow: 1,
		height: selectorHeight,
		listStyle: 'none',
	},
	selector: {
		backgroundColor: theme.palette.grey[200],
		border: 'none',
		cursor: 'pointer',
		height: selectorHeight,
		margin: 0,
		padding: 0,
		transition: '0.3s linear background-color',
		width: '100%',
	},
	// active needs to appear after selector, otherwise it won't override the backgroundColor
	active: {
		backgroundColor: theme.palette.brand.green,
	},
}));

const TestimonialPaginationSelector = ({
	activeIndex,
	index,
	onSetActiveIndex,
}) => {
	const classes = useStyles();

	return (
		<li className={classes.container}>
			<button
				type="button"
				onClick={() => onSetActiveIndex(index)}
				className={`${classes.selector} ${
					activeIndex === index ? classes.active : ''
				}`}
				title={`View Testimonial ${index + 1}`}
				aria-label={`View Testimonial ${index + 1}`}
			></button>
		</li>
	);
};

export default TestimonialPaginationSelector;
