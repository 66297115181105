import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	caption: {
		color: theme.palette.grey[800],
		fontSize: 14,
		fontStyle: 'italic',
		fontWeight: 700,
		marginTop: theme.spacing(2),
		textAlign: 'center',
	},
}));

const PhotoCaption = ({ children }) => {
	const classes = useStyles();

	return <figcaption className={classes.caption}>{children}</figcaption>;
};

export default PhotoCaption;
