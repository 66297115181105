import React from 'react';
import { Link } from 'gatsby';
import SbEditable from 'storyblok-react';
import { Button, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	button: {
		backgroundColor: ({ buttonBackgroundColor }) =>
			buttonBackgroundColor.length > 0
				? theme.palette.brand[buttonBackgroundColor[0].color]
				: undefined,
		color: theme.palette.common.white,
		'&:hover': {
			backgroundColor: ({ buttonBackgroundColor }) =>
				buttonBackgroundColor.length > 0
					? theme.palette.brand[buttonBackgroundColor[0].color]
					: undefined,
		},
	},
	container: {
		marginTop: theme.spacing(2),
		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(1),
		},
	},
	leadInText: {
		fontSize: theme.typography.h4.fontSize,
		marginBottom: theme.spacing(2),
	},
}));

const CallToAction = ({ blok }) => {
	const classes = useStyles(blok);
	const { buttonText, leadInText, linkTarget, linkTo } = blok;

	return (
		<SbEditable content={blok}>
			<div className={classes.container}>
				{leadInText && (
					<Typography variant="h3" className={classes.leadInText}>
						{leadInText}
					</Typography>
				)}
				{linkTo.linktype === 'story' && (
					<Button
						className={classes.button}
						component={Link}
						target={linkTarget}
					>
						{buttonText}
					</Button>
				)}
				{linkTo.linktype === 'url' && (
					<Button
						className={classes.button}
						component={Link}
						to={linkTo.url}
						target={linkTarget}
					>
						{buttonText}
					</Button>
				)}
			</div>
		</SbEditable>
	);
};

export default CallToAction;
