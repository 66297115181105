import React from 'react';
import SbEditable from 'storyblok-react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	container: {
		flexBasis: '100%',
		marginBottom: theme.sizes.componentSpacing,
	},
	headline: {
		marginBottom: theme.sizes.gutter,
		textAlign: 'center',
	},
	text: {
		lineHeight: 1.33,
		textAlign: 'center',
	},
}));

const LeadInContent = ({ blok }) => {
	const classes = useStyles();

	return (
		<SbEditable content={blok}>
			{/* We use a header element here in conjunction with -of-type selectors in ContentColumn.  Do NOT change this to a div; that would break styles in ContentColumn. */}
			<header className={classes.container}>
				<Typography
					variant="h1"
					component="h1"
					className={classes.headline}
				>
					{blok.headline}
				</Typography>
				{blok.text && (
					<Typography
						variant="h3"
						component="h2"
						className={classes.text}
					>
						{blok.text}
					</Typography>
				)}
			</header>
		</SbEditable>
	);
};

export default LeadInContent;
