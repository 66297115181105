import React, { useState } from 'react';
import { graphql, Link as GatsbyLink, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import {
	Drawer,
	IconButton,
	List,
	ListItem,
	makeStyles,
	NoSsr,
	Typography,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';

import Container from './Container';
import Link from './Link';
import PrimaryButton from './PrimaryButton';

const changingBreakpoint = 'md';
const brandPercentWidth = 10;
const remainingLinkContainersWidth = 100 - brandPercentWidth;
const useStyles = makeStyles(theme => ({
	brand: {
		width: 90,
		[theme.breakpoints.up(changingBreakpoint)]: {
			order: 4,
			width: `${brandPercentWidth}%`,
		},
		'& > h1': {
			margin: 0,
		},
	},
	brandName: {
		left: '-9999em',
		position: 'absolute',
	},
	container: {
		backgroundColor: theme.palette.common.white,
		boxShadow: theme.boxShadow,
		left: 0,
		padding: theme.spacing(2, 0),
		position: 'fixed',
		top: 0,
		width: '100%',
		zIndex: theme.layers.fixedContent,
	},
	getStartedButton: {
		flexGrow: 1,
		paddingRight: theme.spacing(1),
		textAlign: 'right',
		[theme.breakpoints.up(changingBreakpoint)]: {
			order: 6,
			paddingRight: 0,
		},
	},
	highResLink: {
		display: 'none',
		[theme.breakpoints.up(changingBreakpoint)]: {
			display: 'list-item',
			width: ({ linksInHeader }) =>
				`${remainingLinkContainersWidth / linksInHeader}%`,
		},
	},
	link: {
		color: theme.palette.brand.brown,
		fontSize: '1rem',
		fontWeight: 700,
		textTransform: 'uppercase',
	},
	links: {
		alignItems: 'center',
		display: 'flex',
		listStyle: 'none',
		margin: 0,
		padding: 0,
		textAlign: 'center',
	},
	mobileNavContainer: {
		backgroundColor: theme.palette.brand.gold,
	},
	mobileNavList: {
		width: 250,
	},
	mobileNavListItem: {
		padding: theme.spacing(0, 2),
		'& > a': {
			padding: theme.spacing(4, 0),
		},
		'& + li > a': {
			borderTop: '1px solid white',
		},
	},
	navToggleContainer: {
		[theme.breakpoints.up(changingBreakpoint)]: {
			display: 'none',
		},
	},
}));

const Header = () => {
	const links = [
		{
			html: 'The<br />Restaurants',
			text: 'The Restaurants',
			path: '/the-restaurants/',
		},
		{
			html: 'Services',
			text: 'Services',
			path: '/services/',
		},
		{
			html: 'Blog',
			text: 'Blog',
			path: '/blog/',
		},
		{
			html: 'About Us',
			text: 'About Us',
			path: '/about-us/',
		},
		{
			html: 'Contact',
			text: 'Contact',
			path: '/contact/',
		},
	];
	const linksInHeader = links.length + 1; // +1 accounts for Get Started button
	const classes = useStyles({ linksInHeader });
	const theme = useTheme();
	const isHighResolution = useMediaQuery(
		theme.breakpoints.up(changingBreakpoint)
	);
	const [drawerOpen, setDrawerOpen] = useState(false);
	const data = useStaticQuery(graphql`
		query GetHeaderContent {
			site {
				siteMetadata {
					title
				}
			}
			sporkLogo: file(relativePath: { eq: "spork-orange-small.png" }) {
				childImageSharp {
					fluid(maxWidth: 250) {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
		}
	`);

	return (
		<header className={classes.container}>
			<Container>
				<nav>
					<ul className={classes.links}>
						<li className={classes.brand}>
							<Typography variant="h1">
								<Link
									to="/"
									className={classes.link}
									title="Go to Sporkbytes.com Home"
								>
									<span className={classes.brandName}>
										{data.site.siteMetadata.title}
									</span>
									<Img
										fluid={
											data.sporkLogo.childImageSharp.fluid
										}
										alt={data.site.siteMetadata.title}
									/>
								</Link>
							</Typography>
						</li>
						{links.map((link, index) => (
							<li
								key={index}
								className={classes.highResLink}
								style={{ order: index + 1 }}
							>
								<Link
									to={link.path}
									className={classes.link}
									dangerouslySetInnerHTML={{
										__html: link.html,
									}}
								></Link>
							</li>
						))}
						<NoSsr>
							<li className={classes.getStartedButton}>
								<PrimaryButton
									component={GatsbyLink}
									to="/get-started/"
								>
									Get Started
								</PrimaryButton>
							</li>
						</NoSsr>
						<li className={classes.navToggleContainer}>
							<IconButton
								aria-label="Open navigation"
								onClick={() => setDrawerOpen(true)}
							>
								<MenuIcon />
							</IconButton>
						</li>
					</ul>
					<Drawer
						open={isHighResolution ? false : drawerOpen}
						onClose={() => setDrawerOpen(false)}
						anchor="right"
						classes={{
							paper: classes.mobileNavContainer,
						}}
					>
						<List className={classes.mobileNavList}>
							{links.map((link, index) => (
								<li
									key={index}
									className={classes.mobileNavListItem}
								>
									<ListItem
										component={Link}
										to={link.path}
										className={classes.link}
									>
										{link.text}
									</ListItem>
								</li>
							))}
						</List>
					</Drawer>
				</nav>
			</Container>
		</header>
	);
};

export default Header;
