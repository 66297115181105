import React from 'react';

import PageWrapper from 'components/PageWrapper';
import PartnerTagSelect from 'components/PartnerTagSelect';

import { transform } from '@sporkbytes/storyblok-image';

import theme from 'theme';

const Homepage = ({ blok }) => (
	<PageWrapper
		title={blok.title}
		description={blok.description}
		ogImage={{
			alt: blok.title,
			src: transform(blok.socialSharingImage, {
				height: theme.sizes.socialSharingImage.height,
				width: theme.sizes.socialSharingImage.width,
			}),
		}}
		blok={blok}
		postContent={<PartnerTagSelect />}
	/>
);

export default Homepage;
