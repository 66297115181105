import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

import BlogPostPublishDate from './BlogPostPublishDate';
import Card from './Card';
import Link from './Link';

import { transform } from '@sporkbytes/storyblok-image';

import theme from 'theme';

const useStyles = makeStyles(theme => ({
	description: {
		margin: theme.spacing(1, 0),
	},
	publishDate: {
		display: 'block',
		fontSize: 14,
		fontWeight: 700,
		margin: theme.spacing(2, 0),
		textTransform: 'uppercase',
	},
	title: {
		fontSize: 20,
		fontWeight: 700,
		margin: theme.spacing(2, 0),
	},
}));

const BlogPostCard = ({
	post: {
		content: { hero, introText, publishDate },
		url,
	},
}) => {
	const classes = useStyles();

	return (
		<Card
			borderColor={theme.palette.brand.green}
			image={transform(hero[0].backgroundImage, {
				height: 200,
				smartCrop: true,
				width: 600,
			})}
			imageAlt={hero[0].headline}
			imageHeight={200}
			imageLinkTo={`/${url}`}
		>
			<Typography
				component="h2"
				className={classes.title}
				title={hero[0].headline}
			>
				<Link to={`/${url}`}>{hero[0].headline}</Link>
			</Typography>
			<Typography
				component="p"
				className={classes.description}
				dangerouslySetInnerHTML={{
					__html:
						introText.length > 150
							? `${introText.substr(0, 150)}...`
							: introText,
				}}
			/>
			<BlogPostPublishDate
				publishDate={publishDate}
				className={classes.publishDate}
			/>
		</Card>
	);
};

export default BlogPostCard;
