import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import camelCase from 'lodash.camelcase';

const useStyles = makeStyles(theme => ({
	container: {
		backgroundColor: ({ partnerTag }) =>
			theme.palette.brand[partnerTag.backgroundColor],
		backgroundImage: ({ partnerTag }) =>
			`url('https://cdn.sporkbytes.com/content/partner-tag-backgrounds/${camelCase(
				partnerTag.name
			)}.svg')`,
		backgroundPosition: 'bottom center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: '100%',
		marginTop: ({ marginTop }) => marginTop,
		padding: `${theme.sizes.componentSpacing / 2}px 0`,
		transition:
			'0.5s ease-in-out background-color, 0.5s ease-in-out background-image',
		[theme.breakpoints.up('md')]: {
			padding: `${theme.sizes.componentSpacing}px 0`,
		},
	},
}));

const PartnerTagContainer = ({ children, marginTop, partnerTag }) => {
	const classes = useStyles({ marginTop, partnerTag });

	return <section className={classes.container}>{children}</section>;
};

PartnerTagContainer.propTypes = {
	marginTop: PropTypes.number,
	partnerTag: PropTypes.object.isRequired,
};

export default PartnerTagContainer;
