import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import TestimonialPaginationSelector from './TestimonialPaginationSelector';

const useStyles = makeStyles(theme => ({
	container: {
		borderBottom: `1px solid ${theme.palette.grey[500]}`,
		display: 'flex',
		flexDirection: 'row',
		margin: `${theme.spacing(4)}px auto 0`,
		padding: 0,
		width: '90%',
	},
}));

const TestimonialPagination = ({
	activeIndex,
	numberOfSelectors,
	onSetActiveIndex,
}) => {
	const classes = useStyles();

	return (
		<ol className={classes.container}>
			{Array(numberOfSelectors)
				.fill(null)
				.map((selector, index) => (
					<TestimonialPaginationSelector
						key={index}
						activeIndex={activeIndex}
						index={index}
						onSetActiveIndex={onSetActiveIndex}
					/>
				))}
		</ol>
	);
};

export default TestimonialPagination;
