import React from 'react';

import PageWrapper from 'components/PageWrapper';

import { transform } from '@sporkbytes/storyblok-image';

import theme from 'theme';

const Page = ({ blok, children, ...props }) => (
	<PageWrapper
		title={blok.title}
		description={blok.description}
		ogImage={
			blok.socialSharingImage
				? {
						alt: blok.title,
						src: transform(blok.socialSharingImage, {
							height: theme.sizes.socialSharingImage.height,
							width: theme.sizes.socialSharingImage.width,
						}),
				  }
				: undefined
		}
		blok={blok}
		postContent={children}
		{...props}
	/>
);

export default Page;
