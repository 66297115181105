import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

import { transform } from '@sporkbytes/storyblok-image';

import theme from 'theme';

const useStyles = makeStyles(() => ({
	img: {
		margin: '0 auto',
	},
}));

const breakPointsOrder = ['lg', 'md', 'sm', 'xs'];

const sortBreakpoints = breakpoints =>
	[...breakpoints].sort(
		({ breakpoint: breakpointA }, { breakpoint: breakpointB }) =>
			breakPointsOrder.indexOf(breakpointA) -
			breakPointsOrder.indexOf(breakpointB)
	);

const PictureSrcSet = ({ originalUrl, altText, breakpoints }) => {
	const classes = useStyles();

	return (
		<picture>
			{/* The order must be highest to lowest resolution */}
			{sortBreakpoints(breakpoints).map(({ breakpoint, ...options }) => {
				const srcSet = transform(originalUrl, options);

				return (
					<source
						key={breakpoint}
						srcSet={srcSet}
						media={`(min-width: ${theme.breakpoints.values[breakpoint]}px)`}
					/>
				);
			})}
			<img
				src={transform(originalUrl)}
				alt={altText}
				className={classes.img}
			/>
		</picture>
	);
};

PictureSrcSet.propTypes = {
	originalUrl: PropTypes.string.isRequired,
	altText: PropTypes.string.isRequired,
	breakpoints: PropTypes.arrayOf(
		PropTypes.shape({
			breakpoint: PropTypes.string.isRequired,
			focalPointX: PropTypes.number,
			focalPointY: PropTypes.number,
			height: PropTypes.number,
			smartCrop: PropTypes.bool,
			width: PropTypes.number,
		})
	),
};

export default PictureSrcSet;
