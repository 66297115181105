import React from 'react';
import SbEditable from 'storyblok-react';
import { NoSsr } from '@material-ui/core';

const SnazzyMap = ({ blok }) => (
	<SbEditable content={blok}>
		<NoSsr>
			<iframe
				src={`https://snazzymaps.com/embed/${blok.mapId}`}
				width="100%"
				height={`${blok.height}px`}
				style={{ border: 'none', display: 'block' }}
				title={`Embedded Snazzy Map ID of ${blok.mapId}`}
			></iframe>
		</NoSsr>
	</SbEditable>
);

export default SnazzyMap;
