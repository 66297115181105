import React from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	text: {
		fontSize: '0.75rem',
		marginTop: theme.spacing(2),
		'& a': {
			color: theme.palette.brand.teal,
			fontWeight: 700,
		},
	},
}));

const withRecaptcha = Form => props => {
	const classes = useStyles();
	const { executeRecaptcha } = useGoogleReCaptcha();

	return (
		<>
			<Form
				{...props}
				getRecaptchaToken={() => executeRecaptcha(Form.name)}
			/>
			<Typography className={classes.text}>
				This site is protected by reCAPTCHA and the Google{' '}
				<a
					href="https://policies.google.com/privacy"
					target="_blank"
					rel="noopener noreferrer"
				>
					Privacy Policy
				</a>{' '}
				and{' '}
				<a
					href="https://policies.google.com/terms"
					target="_blank"
					rel="noopener noreferrer"
				>
					Terms of Service
				</a>{' '}
				apply.
			</Typography>
		</>
	);
};

export default withRecaptcha;
