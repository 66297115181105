import React, { useState } from 'react';
import SbEditable from 'storyblok-react';
import { makeStyles } from '@material-ui/core';

import AccordionItem from 'storyblok/components/AccordionItem';

const useStyles = makeStyles(theme => ({
	container: {
		maxWidth: theme.sizes.narrowContentMaxWidth,
		width: '100%',
	},
}));

const AccordionGroup = ({ blok }) => {
	const classes = useStyles();
	const [expandedItem, setExpandedItem] = useState();

	const handleExpansion = itemId => (event, isExpanded) => {
		setExpandedItem(isExpanded ? itemId : undefined);
	};

	return (
		<SbEditable content={blok}>
			<div className={classes.container}>
				{blok.accordionItems.map(accordionItem => (
					<AccordionItem
						key={accordionItem._uid}
						blok={accordionItem}
						expanded={expandedItem === accordionItem._uid}
						onExpand={handleExpansion}
					/>
				))}
			</div>
		</SbEditable>
	);
};

export default AccordionGroup;
