function getBreakPoints(blok, defaultBreakpoints) {
	const parseDecimalInteger = value => parseInt(value, 10);
	let breakpoints;

	if (blok.resizing && blok.resizing.length > 0) {
		breakpoints = blok.resizing;
	} else {
		breakpoints = defaultBreakpoints;
	}

	return breakpoints.map(
		({
			breakpoint,
			focalPointX,
			focalPointY,
			height,
			smartCrop,
			width,
		}) => ({
			breakpoint,
			focalPointX: parseDecimalInteger(focalPointX),
			focalPointY: parseDecimalInteger(focalPointY),
			height: parseDecimalInteger(height),
			smartCrop,
			width: parseDecimalInteger(width),
		})
	);
}

export { getBreakPoints };
