import React from 'react';

import { Link as GatsbyLink } from 'gatsby';
import { Link as MuiLink, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	link: {
		color: theme.palette.primary[500],
		textDecoration: 'none',
		'&:hover,&:active,&:focus': {
			textDecoration: 'underline',
		},
	},
}));

const Link = React.forwardRef(({ children, to, ...props }, ref) => {
	const classes = useStyles();

	return (
		<MuiLink
			className={classes.link}
			component={to ? GatsbyLink : 'a'}
			to={to}
			ref={ref}
			{...props}
		>
			{children}
		</MuiLink>
	);
});

export default Link;
