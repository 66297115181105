import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';

const borderRadius = 4;
const useStyles = starCommon =>
	makeStyles(theme => ({
		star: {
			...starCommon,
			boxShadow: theme.boxShadow,
			textAlign: 'center',
			transform: 'rotate(22.5deg)',
			'&::before, &::after': {
				backgroundColor: ({ backgroundColor }) => backgroundColor,
				borderRadius,
				content: '""',
				display: 'block',
				height: '100%',
				left: 0,
				position: 'absolute',
				top: 0,
				width: '100%',
			},
			'&::before': {
				transform: 'rotate(22.5deg)',
			},
			'&::after': {
				transform: 'rotate(45deg)',
			},
		},
		starInner: {
			...starCommon,
			alignItems: 'center',
			display: 'flex',
			justifyContent: 'center',
			transform: 'rotate(67.5deg)',
			zIndex: 1, // put it on top of the star's ::before and ::after
		},
		content: {
			color: ({ textColor }) => textColor,
			fontSize: ({ fontSize }) => fontSize,
			fontWeight: 700,
			lineHeight: 1,
			transform: 'rotate(-67.5deg)',
		},
	}));

const Star = ({ backgroundColor, fontSize, size, text, textColor }) => {
	const starCommon = {
		backgroundColor,
		borderRadius,
		height: size,
		position: 'relative',
		width: size,
		zIndex: 0, // place it below the GetStarted button
	};
	const classes = useStyles(starCommon)({
		backgroundColor,
		fontSize,
		textColor,
	});

	return (
		<div className={classes.star}>
			<div className={classes.starInner}>
				<Typography className={classes.content}>{text}</Typography>
			</div>
		</div>
	);
};

Star.propTypes = {
	backgroundColor: PropTypes.string.isRequired,
	fontSize: PropTypes.number.isRequired,
	size: PropTypes.number.isRequired,
	text: PropTypes.string.isRequired,
	textColor: PropTypes.string.isRequired,
};

export default Star;
