import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import SbEditable from 'storyblok-react';

import StoryblokComponents from 'storyblok/';
import Container from 'components/Container';
import SectionHeadline from 'components/SectionHeadline';
import TextButton from 'components/TextButton';

const useStyles = makeStyles(theme => ({
	metrics: {
		backgroundColor: theme.palette.common.white,
		marginBottom: theme.sizes.componentSpacing,
		marginTop: theme.sizes.componentSpacing,
		padding: theme.spacing(4, 0),
	},
	headline: {
		margin: theme.spacing(2, 0),
	},
	metric: {
		padding: theme.spacing(2, 4),
	},
}));

const Metrics = ({ blok }) => {
	const classes = useStyles(blok);

	return (
		<SbEditable content={blok}>
			<section>
				<Container>
					<Grid
						item
						container
						direction="column"
						justify="center"
						alignItems="center"
						className={classes.metrics}
					>
						<SectionHeadline>{blok.headline}</SectionHeadline>
						<Grid item container justify="center">
							{blok.metrics.map(metric => (
								<Grid
									item
									className={classes.metric}
									key={metric._uid}
									xs={12}
									sm={6}
									md={3}
								>
									{React.createElement(
										StoryblokComponents(metric.component),
										{
											key: metric._uid,
											blok: metric,
										}
									)}
								</Grid>
							))}
						</Grid>
						{blok.linkTo && blok.linkTo.url && (
							<TextButton
								action={blok.linkTo.url}
								text={blok.linkText}
								title={`${blok.linkText} about ${blok.headline}`}
							/>
						)}
					</Grid>
				</Container>
			</section>
		</SbEditable>
	);
};

export default Metrics;
