function classifyAction(action) {
	const absoluteUrlRegex = /http[s]?/;
	const actionTypes = [
		{
			type: 'anchorUrl',
			filter: action =>
				typeof action === 'string' && action.startsWith('#'),
		},
		{
			type: 'absoluteUrl',
			filter: action =>
				typeof action === 'string' && action.match(absoluteUrlRegex),
		},
		{
			type: 'relativeUrl',
			filter: action =>
				typeof action === 'string' && !action.match(absoluteUrlRegex),
		},
		{
			type: 'function',
			filter: action => typeof action === 'function',
		},
	];
	const { type } = actionTypes.find(({ filter }) => filter(action));

	return type;
}

export { classifyAction };
