import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { makeStyles, Grid } from '@material-ui/core';

import BlogPostCard from 'components/BlogPostCard';
import Container from 'components/Container';
import IntroText from 'components/IntroText';
import PageWrapper from 'components/PageWrapper';
import SectionHeadline from 'components/SectionHeadline';

import Hero from 'storyblok/components/Hero';

import { transform } from '@sporkbytes/storyblok-image';

import theme from 'theme';

const useStyles = makeStyles(theme => ({
	container: {
		paddingTop: theme.sizes.componentSpacing,
	},
}));

const BlogPost = ({ blok, currentSlug }) => {
	const data = useStaticQuery(graphql`
		query GetLatestBlogPosts {
			allStoryblokEntry(
				filter: { field_component: { eq: "BlogPost" } }
				sort: { fields: field_publishDate_string, order: DESC }
				limit: 4
			) {
				nodes {
					content
					full_slug
					slug
				}
			}
		}
	`);
	const classes = useStyles();

	const [hero] = !!blok.hero ? blok.hero : [];
	const { introText } = blok;
	const mostRecentBlogPosts = data.allStoryblokEntry.nodes
		.filter(({ slug }) => slug !== currentSlug)
		.slice(0, 3);

	return (
		<PageWrapper
			title={hero ? hero.headline : ''}
			description={introText}
			ogImage={{
				alt: hero ? hero.headline : '',
				src:
					hero && hero.backgroundImage
						? transform(hero.backgroundImage, {
								height: theme.sizes.socialSharingImage.height,
								width: theme.sizes.socialSharingImage.width,
						  })
						: '',
			}}
			blok={blok}
			elementType="article"
			preContent={
				<>
					{hero && (
						<Hero
							blok={{
								...hero,
								publishDate: blok.publishDate,
							}}
						/>
					)}
					{introText && (
						<IntroText
							backgroundColor={
								blok.introTextBackgroundColor?.[0]?.color
							}
						>
							{introText}
						</IntroText>
					)}
				</>
			}
			postContent={
				<Container className={classes.container}>
					<SectionHeadline>See more of our blog</SectionHeadline>
					<Grid container spacing={2} justify="center">
						{mostRecentBlogPosts.map(({ content, full_slug }) => (
							<Grid key={full_slug} item xs={12} sm={6} md={4}>
								<BlogPostCard
									post={{
										content: JSON.parse(content),
										url: full_slug,
									}}
								/>
							</Grid>
						))}
					</Grid>
				</Container>
			}
		/>
	);
};

export default BlogPost;
