import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	container: {
		margin: '0 auto',
		maxWidth: theme.sizes.contentMaxWidth,
		padding: `0 ${theme.sizes.gutter}px`,
	},
}));

const Container = ({ children, className }) => {
	const classes = useStyles();

	return (
		<div className={`${classes.container} ${className || ''}`}>
			{children}
		</div>
	);
};

export default Container;
