import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image/withIEPolyfill';
import { Avatar, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Chip } from '@sporkbytes/material-ui-kit-react';

const useStyles = makeStyles(theme => ({
	avatar: {
		background: theme.palette.common.white,
		border: `1px solid ${theme.palette.grey[400]}`,
		height: 75,
		marginRight: theme.spacing(2),
		width: 75,
	},
	avatarImg: {
		height: 'auto',
		margin: 'auto',
	},
	container: {
		paddingTop: 34, // matches top margin of menu headings, making the transition between tabs a bit smoother
	},
	description: {
		margin: theme.spacing(2, 0),
	},
	headingContainer: {
		margin: theme.spacing(2, 0),
	},
	image: {
		height: '100%',
		objectFit: 'cover',
	},
	imageContainer: {
		height: 200,
	},
}));

const PartnerDetail = ({ partner }) => {
	const classes = useStyles();

	return (
		<div className={classes.container}>
			<div className={classes.imageContainer}>
				<Img
					fluid={partner.localPhoto.childImageSharp.fluid}
					className={classes.image}
				/>
			</div>
			<Grid
				container
				alignItems="center"
				wrap="nowrap"
				className={classes.headingContainer}
			>
				<Grid item>
					<Avatar
						alt={partner.name}
						classes={{
							img: classes.avatarImg,
							root: classes.avatar,
						}}
					>
						<Img fixed={partner.localLogo.childImageSharp.fixed} />
					</Avatar>
				</Grid>
				<Grid item>
					<Typography variant="h4" component="h1">
						{partner.name}
					</Typography>
				</Grid>
			</Grid>
			<Typography
				component="div"
				dangerouslySetInnerHTML={{ __html: partner.description }}
				className={classes.description}
			/>
			{partner.PartnerTags.map(tag => (
				<Chip label={tag.name} key={tag.name} />
			))}
		</div>
	);
};

PartnerDetail.propTypes = {
	partner: PropTypes.oneOfType([
		// the initial state is an empty object
		PropTypes.shape({}),
		PropTypes.shape({
			description: PropTypes.string,
			logoUrl: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			PartnerTags: PropTypes.array.isRequired,
			photoUrl: PropTypes.string.isRequired,
		}),
	]),
};

export default PartnerDetail;
