import React from 'react';
import SbEditable from 'storyblok-react';
import { makeStyles } from '@material-ui/core';

import PhotoCaption from 'components/PhotoCaption';
import PictureSrcSet from 'components/PictureSrcSet';

import theme from 'theme';
import { getBreakPoints } from 'services/image';

const useStyles = makeStyles(theme => ({
	container: {
		backgroundColor: ({ backgroundColor: [{ color }] }) =>
			color ? theme.palette.brand[color] : '',
	},
	figure: {
		margin: '0 auto',
		maxWidth: theme.sizes.contentMaxWidth,
		padding: `${theme.sizes.componentSpacing / 2}px ${
			theme.sizes.gutter
		}px`,
		textAlign: 'center',
		[theme.breakpoints.up('md')]: {
			padding: `${theme.sizes.componentSpacing}px ${theme.sizes.gutter}px`,
		},
	},
}));

const defaultBreakpoints = [
	{
		breakpoint: 'lg',
		height: 600,
		width: theme.sizes.photos.large,
	},
	{
		breakpoint: 'md',
		height: 450,
		width: theme.sizes.photos.medium,
	},
	{
		breakpoint: 'sm',
		height: 0,
		width: theme.sizes.photos.small,
	},
	{
		breakpoint: 'xs',
		height: 0,
		width: theme.sizes.photos.extraSmall,
	},
];

const StandalonePhoto = ({ blok }) => {
	const classes = useStyles(blok);

	const { photo, caption } = blok;

	return (
		<SbEditable content={blok}>
			<section className={classes.container}>
				<figure className={classes.figure}>
					<PictureSrcSet
						originalUrl={photo}
						altText={caption}
						breakpoints={getBreakPoints(blok, defaultBreakpoints)}
					/>
					{caption && <PhotoCaption>{caption}</PhotoCaption>}
				</figure>
			</section>
		</SbEditable>
	);
};

export default StandalonePhoto;
