import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

import StoryblokComponents from 'storyblok/';

const verticalAlignmentToFlexMap = {
	top: 'flex-start',
	center: 'center',
	bottom: 'flex-end',
};

const useStyles = makeStyles(theme => ({
	container: {
		alignItems: 'center',
		display: 'flex',
		flex: 1,
		flexBasis: '50%',
		flexDirection: 'column',
		justifyContent: ({ verticalAlignment }) =>
			verticalAlignmentToFlexMap[verticalAlignment],
		'& + &': {
			marginTop: theme.sizes.componentSpacing / 2,
			[theme.breakpoints.up('md')]: {
				marginTop: 0,
			},
		},
		// We use -of-type selectors here in conjunction with LeadInContent being a header element.
		// this is equivalent to :not(:only-of-type), but only-of-type isn't supported in IE11
		'&:first-of-type:not(:last-of-type)': {
			[theme.breakpoints.up('md')]: {
				paddingRight: theme.sizes.componentSpacing / 4,
			},
			[theme.breakpoints.up('lg')]: {
				paddingRight: theme.sizes.componentSpacing / 2,
			},
		},
		// this is equivalent to :not(:first-of-type):not(:only-of-type), but only-of-type isn't supported in IE11
		'&:first-of-type:not(:last-of-type) + &': {
			[theme.breakpoints.up('md')]: {
				paddingLeft: theme.sizes.componentSpacing / 4,
			},
			[theme.breakpoints.up('lg')]: {
				paddingLeft: theme.sizes.componentSpacing / 2,
			},
		},
	},
}));

const ContentColumn = ({ blok, verticalAlignment }) => {
	const classes = useStyles({ verticalAlignment });

	return (
		<div className={classes.container}>
			{blok.content.map(blok =>
				React.createElement(StoryblokComponents(blok.component), {
					key: blok._uid,
					blok: blok,
				})
			)}
		</div>
	);
};

ContentColumn.propTypes = {
	verticalAlignment: PropTypes.oneOf(['top', 'center', 'bottom']).isRequired,
};

export default ContentColumn;
