import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	headline: {
		marginBottom: theme.spacing(4),
		textAlign: 'center',
	},
}));

const SectionHeadline = ({ children }) => {
	const classes = useStyles();

	return (
		<Typography variant="h3" component="h1" className={classes.headline}>
			{children}
		</Typography>
	);
};

export default SectionHeadline;
