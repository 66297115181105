import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import SbEditable from 'storyblok-react';
import { Button, makeStyles, Typography } from '@material-ui/core';

import Container from 'components/Container';
import PartnerTagContainer from 'components/PartnerTagContainer';

const useStyles = makeStyles(theme => ({
	button: {
		backgroundColor: theme.palette.common.white,
		color: theme.palette.brand.teal,
		fontSize: theme.typography.h2.fontSize,
		margin: theme.spacing(4, 0),
		'&:hover': {
			backgroundColor: theme.palette.common.white,
		},
	},
	leadInText: {
		color: theme.palette.common.white,
		fontSize: theme.typography.h1.fontSize,
		fontWeight: 'bold',
		marginBottom: theme.spacing(2),
		textShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
	},
}));

const PartnerTagCallToAction = ({ blok }) => {
	const classes = useStyles(blok);
	const { buttonText, leadInText, linkTarget, linkTo } = blok;
	const data = useStaticQuery(graphql`
		query GetPartnerTagsForPartnerTagCallToAction {
			allPartnerTag(filter: { name: { eq: "Sandwiches / Sliders" } }) {
				nodes {
					id
					name
					backgroundColor
				}
			}
		}
	`);

	return (
		<SbEditable content={blok}>
			<PartnerTagContainer partnerTag={data.allPartnerTag.nodes[0]}>
				<Container>
					{leadInText && (
						<Typography
							component="h1"
							className={classes.leadInText}
						>
							{leadInText}
						</Typography>
					)}
					{linkTo.linktype === 'story' && (
						<Button
							className={classes.button}
							component={Link}
							to={linkTo.cached_url}
							target={linkTarget}
						>
							{buttonText}
						</Button>
					)}
					{linkTo.linktype === 'url' && (
						<Button
							className={classes.button}
							component={Link}
							to={linkTo.url}
							target={linkTarget}
						>
							{buttonText}
						</Button>
					)}
				</Container>
			</PartnerTagContainer>
		</SbEditable>
	);
};

export default PartnerTagCallToAction;
