import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

const BlogPostPublishDate = ({ className, publishDate }) => (
	<time
		dateTime={publishDate.substr(0, publishDate.indexOf(' '))}
		className={className}
	>
		{format(new Date(publishDate.replace(' ', 'T')), 'MMMM d, yyyy')}
	</time>
);

BlogPostPublishDate.propTypes = {
	publishDate: PropTypes.string.isRequired,
};

export default BlogPostPublishDate;
