import React from 'react';
import { makeStyles } from '@material-ui/core';

const ribbonSize = 16;
const shadowOffset = 3;
const useStyles = makeStyles(theme => ({
	container: {
		display: 'flex',
		height: ribbonSize * 2,
		margin: theme.spacing(2, 0, 2, -2), // -2 makes it flush with the left of a Card's content
		maxWidth: '50%',
		position: 'relative', // context for ::before and ::after
		[theme.breakpoints.up('md')]: {
			marginLeft: theme.spacing(-4), // -4 makes it flush with the left of a Card's content
		},
		'&::before, &::after': {
			borderStyle: 'solid',
			borderWidth: `${ribbonSize}px ${ribbonSize / 2}px`,
			content: '""',
			display: 'block',
			position: 'absolute',
		},
		// ribbon
		'&::before': {
			borderColor: `${theme.palette.brand.salmon} transparent ${theme.palette.brand.salmon} ${theme.palette.brand.salmon}`,
			right: -ribbonSize,
			top: 0,
			width: ribbonSize,
			zIndex: 1, // position on top of ::after
		},
		// shadow
		'&::after': {
			borderColor: `${theme.palette.grey[500]} transparent ${
				theme.palette.grey[500]
			} ${theme.palette.grey[500]}`,
			filter: 'blur(2px)',
			right: -ribbonSize - shadowOffset,
			top: shadowOffset,
			width: `calc(100% + ${ribbonSize}px + ${shadowOffset}px)`,
			zIndex: 0, // position below ::before
		},
	},
	content: {
		alignItems: 'center',
		backgroundColor: theme.palette.brand.salmon,
		color: theme.palette.common.white,
		display: 'flex',
		fontWeight: 700,
		height: '100%',
		paddingLeft: theme.spacing(2), // lines up with Card content
		width: '100%',
		zIndex: 1,
		[theme.breakpoints.up('md')]: {
			paddingLeft: theme.spacing(4), // lines up with Card content
		},
	},
}));

const FeaturedRibbon = () => {
	const classes = useStyles();

	return (
		<div className={classes.container}>
			<span className={classes.content}>Featured</span>
		</div>
	);
};

export default FeaturedRibbon;
