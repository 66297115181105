import React from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';
import {
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	makeStyles,
	NoSsr,
	Typography,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import ReactMarkDown from 'react-markdown';

const useStyles = makeStyles(theme => ({
	content: {
		// this is equivalent to :only-child, but only-child isn't supported in IE11
		'& > p:first-child:last-child': {
			margin: 0,
		},
		'& > *:last-child': {
			marginBottom: 0,
		},
	},
	panelDetailsRoot: {
		paddingLeft: 0,
		paddingRight: 0,
	},
	// this empty class needs to be here to apply specificity below with the panelRoot; see https://material-ui.com/customization/components/#use-rulename-to-reference-a-local-rule-within-the-same-style-sheet
	panelExpanded: {},
	panelRoot: {
		borderTop: `1px solid ${theme.palette.grey[300]}`,
		boxShadow: 'none',
		// this is the "border" between elements that Material UI generates; we'll define our own with actual borders
		'&::before': {
			display: 'none',
		},
		'&$panelExpanded': {
			margin: 0,
		},
		'&:last-child': {
			borderBottom: `1px solid ${theme.palette.grey[300]}`,
		},
	},
	panelSummaryRoot: {
		paddingLeft: 0,
	},
}));

const AccordionItem = ({ blok, expanded, onExpand }) => {
	const classes = useStyles();

	return (
		<SbEditable content={blok}>
			<ExpansionPanel
				expanded={expanded}
				onChange={onExpand(blok._uid)}
				classes={{
					root: classes.panelRoot,
					expanded: classes.panelExpanded,
				}}
			>
				<ExpansionPanelSummary
					expandIcon={
						<NoSsr>
							<ExpandMoreIcon color="primary" fontSize="large" />
						</NoSsr>
					}
					classes={{
						root: classes.panelSummaryRoot,
					}}
				>
					<Typography variant="h4" component="span">
						{blok.headline}
					</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails
					classes={{ root: classes.panelDetailsRoot }}
				>
					<ReactMarkDown className={classes.content}>
						{blok.content}
					</ReactMarkDown>
				</ExpansionPanelDetails>
			</ExpansionPanel>
		</SbEditable>
	);
};

AccordionItem.propTypes = {
	expanded: PropTypes.bool.isRequired,
	onExpand: PropTypes.func.isRequired,
};

export default AccordionItem;
