import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import SbEditable from 'storyblok-react';

import StoryblokComponents from 'storyblok/';
import Container from 'components/Container';
import SectionHeadline from 'components/SectionHeadline';

const useStyles = makeStyles(theme => ({
	flipCard: {
		padding: theme.spacing(2, 4),
	},
	flipCards: {
		backgroundColor: theme.palette.common.white,
		marginBottom: theme.sizes.componentSpacing,
		marginTop: theme.sizes.componentSpacing,
		padding: theme.spacing(4, 0),
	},
	headline: {
		margin: theme.spacing(2, 0),
	},
}));

const FlipCards = ({ blok }) => {
	const classes = useStyles(blok);

	return (
		<SbEditable content={blok}>
			<section>
				<Container>
					<Grid
						item
						container
						direction="column"
						justify="center"
						alignItems="center"
						className={classes.flipCards}
					>
						<SectionHeadline>{blok.headline}</SectionHeadline>
						<Grid item container justify="center">
							{blok.flipCards.map(flipCard => (
								<Grid
									item
									className={classes.flipCard}
									key={flipCard._uid}
									xs={12}
									sm={6}
									md={4}
								>
									{React.createElement(
										StoryblokComponents(flipCard.component),
										{
											key: flipCard._uid,
											blok: flipCard,
										}
									)}
								</Grid>
							))}
						</Grid>
					</Grid>
				</Container>
			</section>
		</SbEditable>
	);
};

export default FlipCards;
