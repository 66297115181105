import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	container: {
		margin: '0 auto',
		maxWidth: theme.sizes.narrowContentMaxWidth,
		padding: `${theme.sizes.componentSpacing}px ${theme.sizes.gutter}px`,
	},
	wrapper: {
		backgroundColor: 'white',
	},
}));

const FormContainer = ({ children }) => {
	const classes = useStyles();

	return (
		<div className={classes.wrapper}>
			<div className={classes.container}>
				<noscript
					style={{
						backgroundColor: 'yellow',
						display: 'block',
						fontWeight: 'bold',
						marginBottom: '1em',
						padding: '1em',
						textAlign: 'center',
					}}
				>
					If you want to fill out this form, you must{' '}
					<a
						href="https://enable-javascript.com/"
						target="_blank"
						rel="noopener noreferrer"
					>
						enable JavaScript
					</a>
					.
				</noscript>
				{children}
			</div>
		</div>
	);
};

export default FormContainer;
