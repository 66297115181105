import React, { useState } from 'react';
import SbEditable from 'storyblok-react';
import { Grid, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	container: {
		margin: '0 auto',
	},
	photoCard: {
		backfaceVisibility: 'hidden',
		height: '100%',
		left: 0,
		position: 'absolute',
		top: 0,
		width: '100%',
	},
	photoCardBack: {
		transform: 'rotateY(180deg)',
	},
	photoInnerContainer: {
		borderTop: ({ borderColor }) =>
			borderColor && borderColor.length > 0
				? `16px solid ${theme.palette.brand[borderColor[0].color]}`
				: null,
		position: 'relative',
		transition: '0.5s ease-in-out transform',
		transformStyle: 'preserve-3d',
	},
	photoInnerContainerFlipped: {
		transform: 'rotateY(180deg)',
	},
	photoOuterContainer: {
		height: 300,
		perspective: 1000,
		width: 250,
	},
	secondaryText: {
		fontSize: theme.typography.h4.fontSize,
		fontWeight: 'normal',
		textAlign: 'center',
	},
	title: {
		color: theme.palette.primary[500],
		marginTop: theme.spacing(1),
		fontSize: theme.typography.h2.fontSize,
		textAlign: 'center',
	},
}));

const FlipCard = ({ blok }) => {
	const classes = useStyles(blok);
	const [showFlippedPhoto, setShowFlippedPhoto] = useState(false);

	return (
		<SbEditable content={blok}>
			<Grid container justify="center" direction="column">
				<div className={classes.container}>
					<div
						className={classes.photoOuterContainer}
						onMouseEnter={() =>
							setShowFlippedPhoto(!showFlippedPhoto)
						}
						onMouseLeave={() =>
							setShowFlippedPhoto(!showFlippedPhoto)
						}
					>
						<div
							className={`${classes.photoInnerContainer} ${
								showFlippedPhoto
									? classes.photoInnerContainerFlipped
									: ''
							}`}
						>
							<div className={`${classes.photoCard}`}>
								<img src={blok.defaultPhoto} alt={blok.title} />
							</div>
							<div
								className={`${classes.photoCard} ${classes.photoCardBack}`}
							>
								<img src={blok.flippedPhoto} alt={blok.title} />
							</div>
						</div>
					</div>
					<Typography component="h2" className={classes.title}>
						{blok.title}
					</Typography>
					{blok.title ? (
						<Typography
							component="h3"
							className={classes.secondaryText}
						>
							{blok.subtitle}
						</Typography>
					) : null}
					<Typography className={classes.secondaryText}>
						{blok.description}
					</Typography>
				</div>
			</Grid>
		</SbEditable>
	);
};

export default FlipCard;
