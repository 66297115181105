import React from 'react';
import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	button: {
		backgroundColor: theme.palette.common.white,
		[theme.breakpoints.up('lg')]: {
			padding: theme.spacing(1, 2),
		},
		'&:hover': {
			backgroundColor: theme.palette.grey[100],
		},
		'&[disabled]': {
			cursor: 'default',
			opacity: 0.7,
		},
	},
}));

const SecondaryButton = ({ children, className, ...props }) => {
	const classes = useStyles();

	return (
		<Button {...props} className={`${classes.button} ${className || ''}`}>
			{children}
		</Button>
	);
};

export default SecondaryButton;
