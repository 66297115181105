import React from 'react';
import SbEditable from 'storyblok-react';
import ReactMarkDown from 'react-markdown';
import { Link } from 'gatsby';
import { makeStyles } from '@material-ui/core';

import PhotoCaption from 'components/PhotoCaption';
import PictureSrcSet from 'components/PictureSrcSet';

import theme from 'theme';
import { getBreakPoints } from 'services/image';

const defaultBreakpoints = [
	{ breakpoint: 'lg', height: 0, width: theme.sizes.photos.large },
	{ breakpoint: 'md', height: 0, width: theme.sizes.photos.medium },
	{ breakpoint: 'sm', height: 0, width: theme.sizes.photos.small },
	{ breakpoint: 'xs', height: 0, width: theme.sizes.photos.extraSmall },
];

const useStyles = makeStyles(theme => ({
	caption: {
		'& a': {
			color: theme.palette.primary[500],
			fontWeight: 700,
		},
	},
	container: {
		margin: '0 auto',
		maxWidth: '100%', // needed to keep figures from expanding beyond their parent in IE11
		minHeight: 1, // needed to prevent IE11 from stretching the height of the container to the height it would be if the containing image were not resized to max-width: 100%; https://github.com/philipwalton/flexbugs/issues/75#issuecomment-134702421
		'& + &': {
			marginTop: theme.sizes.gutter,
		},
	},
}));

const Photo = ({ blok }) => {
	const classes = useStyles();
	const caption = blok.caption ? (
		<ReactMarkDown className={classes.caption}>
			{blok.caption}
		</ReactMarkDown>
	) : blok.useAltTextAsCaption ? (
		blok.altText
	) : (
		undefined
	);
	const Wrapper = blok.linkTo
		? ({ children }) => (
				<Link
					to={blok.linkTo.cached_url ?? blok.linkTo.url}
					target={blok.linkTarget}
				>
					{children}
				</Link>
		  )
		: ({ children }) => <>{children}</>;

	return (
		<SbEditable content={blok}>
			<figure className={classes.container}>
				<Wrapper>
					<PictureSrcSet
						originalUrl={blok.photo}
						altText={blok.altText}
						breakpoints={getBreakPoints(blok, defaultBreakpoints)}
					/>
				</Wrapper>
				{caption && <PhotoCaption>{caption}</PhotoCaption>}
			</figure>
		</SbEditable>
	);
};

export default Photo;
