import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import MenuSection from './MenuSection';

const useStyles = makeStyles(theme => ({
	mealTypeHeadingContainer: {
		paddingTop: theme.spacing(3),
		textAlign: 'center',
	},
}));

const Menu = ({ menu: { MealTypes, PartnerListMenuSections } }) => {
	const classes = useStyles();

	return (
		<div>
			{MealTypes && (
				<div className={classes.mealTypeHeadingContainer}>
					<Typography variant="h2" component="h1">
						Available for:
					</Typography>
					<Typography>
						{MealTypes.map(({ name }) => name).join(', ')}
					</Typography>
				</div>
			)}
			{PartnerListMenuSections.map((section, index) => (
				<MenuSection
					key={index}
					name={section.name}
					items={section.PartnerMenuItems}
				/>
			))}
		</div>
	);
};

Menu.propTypes = {
	menu: PropTypes.shape({
		MealTypes: PropTypes.array,
		PartnerListMenuSections: PropTypes.array.isRequired,
	}),
};

export default Menu;
