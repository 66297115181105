import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image/withIEPolyfill';
import { makeStyles } from '@material-ui/core/styles';
import objectFitImages from 'object-fit-images';

import Link from './Link';

const useStyles = makeStyles(theme => ({
	actions: {
		marginTop: 'auto',
		padding: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px`,
		[theme.breakpoints.up('md')]: {
			padding: `0 ${theme.spacing(4)}px ${theme.spacing(4)}px`,
		},
	},
	button: {
		fontWeight: 700,
	},
	card: {
		backgroundColor: theme.palette.common.white,
		borderTop: ({ borderColor }) =>
			`${theme.sizes.topBorderWidth}px solid ${borderColor}`,
		boxShadow: theme.boxShadow,
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
	},
	content: {
		padding: theme.spacing(2),
		position: 'relative', // provide positioning context for elements like the "NEW!" Star
		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(4),
		},
	},
	image: {
		fontFamily: `'object-fit: cover; object-position: 50% 50%;'`, // needed for IE11 to run object-fit-images plugin https://github.com/fregante/object-fit-images#usage
		height: '100%',
		objectFit: 'cover',
	},
	imageContainer: {
		height: ({ imageHeight }) => imageHeight,
		overflow: 'hidden',
	},
}));

const ImageContainer = ({ image, alt }) => {
	const classes = useStyles();

	if (typeof image === 'object') {
		return <Img fluid={image} alt={alt} className={classes.image} />;
	}

	return (
		<img src={image} alt={alt} className={`${classes.image} card-image`} />
	);
};

const Card = ({
	action,
	borderColor,
	children,
	image,
	imageAlt,
	imageHeight,
	imageLinkTo,
}) => {
	const classes = useStyles({ borderColor, imageHeight });

	const ImageWrapper = imageLinkTo ? Link : props => <div {...props}></div>;

	useEffect(() => {
		objectFitImages('img.card-image', { watchMQ: true });
	}, []);

	return (
		<div className={classes.card}>
			<ImageWrapper className={classes.imageContainer} to={imageLinkTo}>
				<ImageContainer image={image} alt={imageAlt} />
			</ImageWrapper>
			<div className={classes.content}>{children}</div>
			{action ? <div className={classes.actions}>{action}</div> : null}
		</div>
	);
};

Card.propTypes = {
	action: PropTypes.element,
	borderColor: PropTypes.string.isRequired,
	image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	imageAlt: PropTypes.string.isRequired,
	imageHeight: PropTypes.number,
	imageLinkTo: PropTypes.string,
};

Card.defaultProps = {
	imageHeight: 150,
};

export default Card;
