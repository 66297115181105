import React from 'react';
import SbEditable from 'storyblok-react';
import ReactMarkDown from 'react-markdown';
import { Grid, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	container: {
		fontSize: ({ lowResolutionFontSize }) =>
			parseInt(lowResolutionFontSize, 10) || theme.typography.fontSize,
		maxWidth: theme.sizes.narrowContentMaxWidth,
		overflow: 'auto', // allows the top margins of headlines, if present, to push their content down
		width: '100%', // needed for IE11 to display the content at the proper width
		'& + &': {
			marginTop: theme.sizes.gutter,
		},
		[theme.breakpoints.up('sm')]: {
			fontSize: ({ highResolutionFontSize }) =>
				parseInt(highResolutionFontSize, 10) ||
				theme.typography.fontSize,
		},
	},
	content: {
		[theme.breakpoints.up('md')]: {
			columns: ({ columns }) => parseInt(columns, 10),
		},
		'& > p': {
			lineHeight: 2,
			margin: 0,
		},
		'& > p + p': {
			marginTop: theme.spacing(2),
		},
		'& a': {
			color: theme.palette.primary[500],
			fontWeight: 700,
		},
	},
	headlineLarge: {
		margin: '1em 0',
		textAlign: 'center',
	},
	headlineSmall: {
		margin: '1em 0',
		textTransform: 'uppercase',
	},
	quoteText: {
		borderLeft: `8px solid ${theme.palette.brand.pink}`,
		fontSize: 18,
		lineHeight: 2,
		margin: theme.spacing(2, 0, 0),
		paddingLeft: theme.spacing(1),
		[theme.breakpoints.up('sm')]: {
			margin: theme.spacing(0, 0, 2, 2),
		},
	},
}));

const TextContent = ({ blok }) => {
	const classes = useStyles(blok);

	const Headline = ({ size, children }) =>
		size === 'large' ? (
			<Typography
				variant="h2"
				component="h1"
				className={classes.headlineLarge}
			>
				{children}
			</Typography>
		) : (
			<Typography
				variant="h5"
				component="h2"
				className={classes.headlineSmall}
			>
				{children}
			</Typography>
		);

	return (
		<SbEditable content={blok}>
			<div className={classes.container}>
				{blok.headline && (
					<Headline size={blok.headlineSize}>
						{blok.headline}
					</Headline>
				)}
				<Grid container>
					<Grid item xs={12} sm={blok.quoteText ? 9 : 12}>
						<ReactMarkDown className={classes.content}>
							{blok.content}
						</ReactMarkDown>
					</Grid>
					<Grid item xs={12} sm={3}>
						{blok.quoteText && (
							<blockquote className={classes.quoteText}>
								"{blok.quoteText}"
							</blockquote>
						)}
					</Grid>
				</Grid>
			</div>
		</SbEditable>
	);
};

export default TextContent;
