import React from 'react';
import { graphql, Link as GatsbyLink, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import { Button, Grid, makeStyles, NoSsr, Typography } from '@material-ui/core';
import { Facebook, Instagram, Twitter } from '@material-ui/icons';
import { PhoneNumber } from '@sporkbytes/material-ui-kit-react';

import Container from './Container';
import Link from './Link';

const useStyles = makeStyles(theme => ({
	brand: {
		left: '-9999em',
		position: 'absolute',
	},
	button: {
		backgroundColor: theme.palette.common.white,
		color: theme.palette.brand.pink,
		padding: theme.spacing(1, 2),
		'&:hover': {
			backgroundColor: theme.palette.common.white,
		},
	},
	contactInfo: {
		lineHeight: 1.5,
		margin: 0,
	},
	container: {
		backgroundColor: theme.palette.brand.pink,
		color: theme.palette.common.white,
		padding: theme.spacing(4, 0),
		textAlign: 'center',
	},
	link: {
		color: theme.palette.common.white,
		display: 'inline-block',
		margin: theme.spacing(1, 0),
	},
	logo: {
		margin: '0 auto',
		maxWidth: 250,
		[theme.breakpoints.up('md')]: {
			maxWidth: 350,
		},
	},
	socialLinks: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
		listStyle: 'none',
		margin: `${theme.spacing(3)}px auto 0`,
		padding: 0,
		width: 140,
	},
	subheading: {
		fontSize: 16,
		marginBottom: theme.spacing(2),
	},
}));

const Footer = () => {
	const classes = useStyles();
	const data = useStaticQuery(graphql`
		query GetFooterContent {
			site {
				siteMetadata {
					title
					instagram
					twitter
					facebook
				}
			}
			sporkLogo: file(relativePath: { eq: "spork-white-small.png" }) {
				childImageSharp {
					fluid(maxWidth: 350) {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			allSporkLocation {
				nodes {
					id
					name
					streetAddress
					streetAddress2
					city
					state
					zipCode
					phoneNumber
					emailAddress
				}
			}
		}
	`);

	const {
		allSporkLocation,
		site: { siteMetadata },
	} = data;

	return (
		<footer className={classes.container}>
			<Container>
				<Grid
					container
					spacing={2}
					itemScope
					itemType="https://schema.org/LocalBusiness"
					alignItems="center"
				>
					<Grid item xs={12} md={4}>
						<Typography variant="h1" className={classes.brand}>
							{siteMetadata.title}
						</Typography>
						<div className={classes.logo}>
							<Img
								fluid={data.sporkLogo.childImageSharp.fluid}
								alt={siteMetadata.title}
								itemProp="image"
							/>
						</div>
					</Grid>
					<Grid item xs={12} md={4}>
						<Typography>
							<span itemProp="name">{siteMetadata.title}</span>{' '}
							&copy; Copyright {new Date().getFullYear()}
							<br />
							All Rights Reserved
						</Typography>
						<Typography>
							<Link
								href="https://sporkbyt.es/privacy-policy"
								target="_blank"
								rel="noopener noreferrer"
								className={classes.link}
							>
								Privacy Policy
							</Link>
						</Typography>
						<Typography>
							<Link to="/cookie-policy/" className={classes.link}>
								Cookie Policy
							</Link>
						</Typography>
					</Grid>
					<Grid item xs={12} md={4}>
						<NoSsr>
							<Button
								component={GatsbyLink}
								to="/partner-with-us/"
								className={classes.button}
							>
								Make Great Food?
							</Button>
						</NoSsr>
						<ul className={classes.socialLinks}>
							<li>
								<Link
									href={`https://instagram.com/${siteMetadata.instagram}`}
									target="_blank"
									rel="noopener noreferrer"
									className={classes.link}
									title="Follow us on Instagram"
								>
									<Instagram fontSize="large" />
								</Link>
							</li>
							<li>
								<Link
									href={`https://twitter.com/${siteMetadata.twitter}`}
									target="_blank"
									rel="noopener noreferrer"
									className={classes.link}
									title="Follow us on Twitter"
								>
									<Twitter fontSize="large" />
								</Link>
							</li>
							<li>
								<Link
									href={`https://facebook.com/${siteMetadata.facebook}`}
									target="_blank"
									rel="noopener noreferrer"
									className={classes.link}
									title="Follow us on Facebook"
								>
									<Facebook fontSize="large" />
								</Link>
							</li>
						</ul>
					</Grid>
					<Grid item xs={12}>
						<Typography
							variant="h4"
							component="h2"
							className={classes.subheading}
						>
							Contact
						</Typography>
						<Grid container spacing={2} justify="center">
							{allSporkLocation.nodes.map(location => (
								<Grid item xs={12} sm={6} md={3}>
									<Typography
										itemProp="address"
										itemScope
										itemType="https://schema.org/PostalAddress"
										className={classes.contactInfo}
									>
										<span itemProp="streetAddress">
											{location.streetAddress}
										</span>{' '}
										<span itemProp="addressLocality">
											{location.city}
										</span>
										{', '}
										<span itemProp="addressRegion">
											{location.state}
										</span>{' '}
										<span itemProp="postalCode">
											{location.zipCode}
										</span>
									</Typography>
									<Typography
										itemProp="telephone"
										className={classes.contactInfo}
									>
										<PhoneNumber
											value={location.phoneNumber}
											renderText={value => (
												<Link
													href={`tel:${location.phoneNumber}`}
													className={classes.link}
												>
													{value}
												</Link>
											)}
										/>
									</Typography>
									<Typography
										itemProp="email"
										className={classes.contactInfo}
									>
										<Link
											href={`mailto:${location.emailAddress}`}
											className={classes.link}
										>
											{location.emailAddress}
										</Link>
									</Typography>
								</Grid>
							))}
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</footer>
	);
};

export default Footer;
