import AccordionGroup from './components/AccordionGroup';
import AccordionItem from './components/AccordionItem';
import BlogPost from './content-types/BlogPost';
import CallToAction from './components/CallToAction';
import ColumnarContentContainer from './components/ColumnarContentContainer';
import ComponentNotFound from './ComponentNotFound';
import ContactInformation from './components/ContactInformation';
import ContentColumn from './components/ContentColumn';
import Feature from './components/Feature';
import Features from './components/Features';
import FlipCard from './components/FlipCard';
import FlipCards from './components/FlipCards';
import Hero from './components/Hero';
import Homepage from './content-types/Homepage';
import LeadInContent from './components/LeadInContent';
import Metric from './components/Metric';
import Metrics from './components/Metrics';
import Page from './content-types/Page';
import PartnerTagCallToAction from './components/PartnerTagCallToAction';
import Photo from './components/Photo';
import SnazzyMap from './components/SnazzyMap';
import StandalonePhoto from './components/StandalonePhoto';
import Testimonial from './components/Testimonial';
import Testimonials from './components/Testimonials';
import TextContent from './components/TextContent';
import VideoEmbed from './components/VideoEmbed';

const ComponentList = {
	AccordionGroup,
	AccordionItem,
	BlogPost,
	CallToAction,
	ColumnarContentContainer,
	ContactInformation,
	ContentColumn,
	Feature,
	Features,
	FlipCard,
	FlipCards,
	Hero,
	Homepage,
	LeadInContent,
	Metric,
	Metrics,
	Page,
	PartnerTagCallToAction,
	Photo,
	SnazzyMap,
	StandalonePhoto,
	Testimonial,
	Testimonials,
	TextContent,
	VideoEmbed,
};

const StoryblokComponents = type => {
	if (typeof ComponentList[type] === 'undefined') {
		return ComponentNotFound;
	}
	return ComponentList[type];
};

export default StoryblokComponents;
