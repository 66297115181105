import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getPartnerMenus } from '@sporkbytes/api-client/PublicPartners';

import MenuDialog from './MenuDialog';

import axios from 'services/axios';
import { sendEvent } from 'services/analytics';

const ViewMenusButton = ({
	children,
	partner,
	showGetStartedButton,
	showPartnerInDialog,
}) => {
	const [isLoadingMenus, setIsLoadingMenus] = useState(false);
	const [partnerDialogOpen, setPartnerDialogOpen] = useState(false);
	const [menus, setMenus] = useState([]);

	const handleClick = async () => {
		setIsLoadingMenus(true);
		setPartnerDialogOpen(true);

		sendEvent({
			action: 'Open Partner Dialog',
			label: `${partner.name} - ${
				partner.isFeaturedPartner ? 'Featured' : 'Non-Featured'
			}`,
		});

		const menus = await getPartnerMenus(axios, partner.id);

		setMenus(menus);
		setIsLoadingMenus(false);
	};

	return (
		<>
			{children(handleClick)}
			<MenuDialog
				menus={menus}
				open={partnerDialogOpen}
				onClose={() => setPartnerDialogOpen(false)}
				isLoading={isLoadingMenus}
				setIsLoading={setIsLoadingMenus}
				partner={partner}
				showPartner={showPartnerInDialog}
				showGetStartedButton={showGetStartedButton}
			/>
		</>
	);
};

ViewMenusButton.propTypes = {
	partner: PropTypes.object.isRequired,
	showGetStartedButton: PropTypes.bool,
	showPartnerInDialog: PropTypes.bool.isRequired,
};

ViewMenusButton.defaultProps = {
	showGetStartedButton: false,
};

export default ViewMenusButton;
