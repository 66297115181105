import React from 'react';
import SbEditable from 'storyblok-react';
import { makeStyles } from '@material-ui/core';

import ContentColumn from 'storyblok/components/ContentColumn';
import LeadInContent from 'storyblok/components/LeadInContent';

const useStyles = makeStyles(theme => ({
	anchor: {
		position: 'absolute',
		top: -theme.sizes.headerHeight,
	},
	container: {
		backgroundColor: ({ backgroundColor: [{ color }] }) =>
			color ? theme.palette.brand[color] : '',
		position: 'relative', // context for anchor
	},
	innerContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-around',
		margin: '0 auto',
		maxWidth: theme.sizes.contentMaxWidth,
		padding: `${theme.sizes.componentSpacing / 2}px ${
			theme.sizes.gutter
		}px`,
		position: 'relative', // context for ::after "border"
		[theme.breakpoints.up('md')]: {
			flexDirection: 'row',
			flexWrap: 'wrap',
			padding: `${theme.sizes.componentSpacing}px ${theme.sizes.gutter}px`,
		},
	},
	innerContainerWithBorder: {
		'&::after': {
			border: `1px solid ${theme.palette.grey[300]}`,
			bottom: '0',
			content: "''",
			display: 'block',
			left: '15%',
			position: 'absolute',
			width: '70%',
		},
	},
}));

const ColumnarContentContainer = ({ blok }) => {
	const classes = useStyles(blok);

	return (
		<SbEditable content={blok}>
			<section className={classes.container}>
				{blok.anchorId && (
					<div id={blok.anchorId} className={classes.anchor}></div>
				)}
				<div
					className={`${classes.innerContainer} ${
						blok.bottomBorder
							? classes.innerContainerWithBorder
							: ''
					}`}
				>
					{blok.leadInContent && blok.leadInContent.length > 0 && (
						<LeadInContent blok={blok.leadInContent[0]} />
					)}
					{blok.contentColumns.map(contentColumn => (
						<ContentColumn
							blok={contentColumn}
							key={contentColumn._uid}
							verticalAlignment={blok.verticalAlignment}
						></ContentColumn>
					))}
				</div>
			</section>
		</SbEditable>
	);
};

export default ColumnarContentContainer;
