import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import {
	AppBar,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Tab,
	Tabs,
	withMobileDialog,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';

import CircularProgress from './ClientOrderRequestForm/CircularProgress';
import Menu from './Menu';
import PartnerDetail from './ClientOrderRequestForm/PartnerDetail';
import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';

import theme from 'theme';

const useStyles = makeStyles(theme => ({
	circularProgressContainer: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		minHeight: '500px',
		minWidth: '300px',
	},
	dialogTitle: {
		left: '-9999em',
		position: 'absolute',
	},
	singleMenuHeading: {
		borderBottom: '2px solid black',
		cursor: 'default',
		display: 'inline-block',
		fontSize: '13px',
		margin: '0 auto',
		padding: theme.spacing(2, 3),
		textAlign: 'center',
		textTransform: 'uppercase',
	},
}));

const MenuDialog = ({
	fullScreen,
	isLoading,
	menus,
	onClose,
	open,
	partner,
	showGetStartedButton,
	showPartner,
}) => {
	const [activeTab, setActiveTab] = useState(0);
	const classes = useStyles();

	const closeDialog = () => {
		onClose();

		setTimeout(() => {
			setActiveTab(0);
		}, 1000);
	};

	return (
		<Dialog
			open={open}
			onClose={closeDialog}
			aria-labelledby="menu-dialog-title"
			fullScreen={fullScreen}
			// This style must be applied inline because Material UI applies the z-index inline and overwrites z-index properties set as part of a class
			style={{
				zIndex: theme.layers.chatWidget + 1,
			}}
		>
			{showPartner && (
				<DialogTitle
					id="menu-dialog-title"
					className={classes.dialogTitle}
				>
					{partner.name} Details
				</DialogTitle>
			)}
			{isLoading ? (
				<DialogContent className={classes.circularProgressContainer}>
					<CircularProgress />
				</DialogContent>
			) : (
				<>
					<AppBar position="static" color="default">
						<Tabs
							value={activeTab}
							onChange={(event, activeTab) =>
								setActiveTab(activeTab)
							}
							indicatorColor="secondary"
							textColor="secondary"
							variant="scrollable"
							scrollButtons="auto"
						>
							{showPartner && <Tab label={partner.name} />}
							{menus.map((menu, index) => (
								<Tab label={menu.name} key={index} />
							))}
						</Tabs>
					</AppBar>
					<DialogContent>
						{/* Unfortunately we can't check for the existence of a partner inside SwipeableViews; it won't render properly when one of its children is null. */}
						{showPartner && (
							<SwipeableViews
								animateHeight
								axis="x"
								index={activeTab}
								onChangeIndex={setActiveTab}
							>
								<PartnerDetail partner={partner} />
								{menus.map((menu, tabIndex) => (
									<Menu menu={menu} key={tabIndex} />
								))}
							</SwipeableViews>
						)}
						{!showPartner && (
							<SwipeableViews
								animateHeight
								axis="x"
								index={activeTab}
								onChangeIndex={setActiveTab}
							>
								{menus.map((menu, tabIndex) => (
									<Menu menu={menu} key={tabIndex} />
								))}
							</SwipeableViews>
						)}
					</DialogContent>
				</>
			)}
			<DialogActions>
				<SecondaryButton disabled={isLoading} onClick={closeDialog}>
					Close
				</SecondaryButton>
				{showGetStartedButton && (
					<PrimaryButton
						component={Link}
						to={`/get-started/?restaurant=${partner.landingPageSlug}`}
					>
						Get Started
					</PrimaryButton>
				)}
			</DialogActions>
		</Dialog>
	);
};

MenuDialog.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	menus: PropTypes.array.isRequired,
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	partner: PropTypes.object.isRequired,
	showGetStartedButton: PropTypes.bool,
	showPartner: PropTypes.bool,
};

export default withMobileDialog()(MenuDialog);
