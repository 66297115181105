import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { Facebook, Link, LinkedIn, Twitter } from '@material-ui/icons';

import { copyText } from 'services/utilities';

const canCopy =
	typeof window !== 'undefined' &&
	typeof window?.navigator?.clipboard?.writeText === 'function';
const defaultCopyButtonTooltip = 'Copy link';

const useStyles = makeStyles(theme => ({
	container: {
		alignItems: 'center',
		display: 'inline-flex',
		justifyContent: 'space-between',
		listStyle: 'none',
		margin: 0,
		padding: 0,
		verticalAlign: 'middle',
	},
	copyButton: {
		padding: 0,
	},
	icon: {
		fill: 'white',
		fontSize: 40,
	},
	listItem: {
		marginLeft: theme.spacing(2),
	},
}));

const SocialSharing = ({ headline }) => {
	const classes = useStyles();
	const [pageUrl, setPageUrl] = useState();
	const [copyButtonTooltip, setCopyButtonTooltip] = useState(
		defaultCopyButtonTooltip
	);
	const platforms = [
		{
			name: 'LinkedIn',
			logo: <LinkedIn className={classes.icon} />,
			url: `https://www.linkedin.com/sharing/share-offsite/?url=${pageUrl}`,
		},
		{
			name: 'Twitter',
			logo: <Twitter className={classes.icon} />,
			url: `https://twitter.com/intent/tweet?url=${pageUrl}&text=${headline}`,
		},
		{
			name: 'Facebook',
			logo: <Facebook className={classes.icon} />,
			url: `https://www.facebook.com/sharer/sharer.php?u=${pageUrl}`,
		},
	];

	useEffect(() => {
		setPageUrl(window.location.href);
	}, []);

	return (
		<ul className={classes.container}>
			{platforms.map(platform => (
				<li key={platform.name} className={classes.listItem}>
					<a
						href={platform.url}
						target="_blank"
						rel="noopener noreferrer"
						title={`Share on ${platform.name}`}
					>
						{platform.logo}
					</a>
				</li>
			))}
			{canCopy ? (
				<li key="copyButton" className={classes.listItem}>
					<Tooltip title={copyButtonTooltip}>
						<IconButton
							aria-label="Copy link to page"
							className={classes.copyButton}
							onClick={() =>
								copyText(pageUrl).then(() => {
									setCopyButtonTooltip('Copied!');

									setTimeout(() => {
										setCopyButtonTooltip(
											defaultCopyButtonTooltip
										);
									}, 2000);
								})
							}
						>
							<Link className={classes.icon} />
						</IconButton>
					</Tooltip>
				</li>
			) : null}
		</ul>
	);
};

SocialSharing.propTypes = {
	headline: PropTypes.string.isRequired,
};

export default SocialSharing;
