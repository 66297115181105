import React from 'react';
import { Grid, CircularProgress } from '@material-ui/core';

const CenteredCircularProgress = () => (
	<Grid container justify="center" alignItems="center">
		<CircularProgress />
	</Grid>
);

export default CenteredCircularProgress;
