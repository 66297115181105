import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import SbEditable from 'storyblok-react';

import { transform } from '@sporkbytes/storyblok-image';

const useStyles = makeStyles(theme => ({
	container: {
		textAlign: 'center',
	},
	headshot: {
		borderRadius: '50%',
		display: 'block',
		margin: `0 auto ${theme.spacing(2)}px auto`,
	},
	name: {
		textTransform: 'uppercase',
	},
	quote: {
		fontSize: 18,
		margin: theme.spacing(2, 0),
		[theme.breakpoints.up('sm')]: {
			fontSize: 24,
		},
	},
}));

const Testimonial = ({ testimonial }) => {
	const classes = useStyles();
	const { companyName, headshot, name, position, quote } = testimonial;

	return (
		<SbEditable content={testimonial}>
			<div className={classes.container}>
				{headshot ? (
					<img
						src={transform(headshot)}
						alt={name}
						className={classes.headshot}
					/>
				) : null}
				<blockquote>
					<Typography className={classes.quote}>{quote}</Typography>
					<footer>
						<Typography variant="h5" component="h2">
							- <span className={classes.name}>{name}</span>,{' '}
							<cite>
								{position} for {companyName}
							</cite>
						</Typography>
					</footer>
				</blockquote>
			</div>
		</SbEditable>
	);
};

export default Testimonial;
