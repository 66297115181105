import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { makeStyles, NoSsr, Typography } from '@material-ui/core';
import SbEditable from 'storyblok-react';

import Container from 'components/Container';
import Testimonial from 'storyblok/components/Testimonial';
import TestimonialPagination from 'components/TestimonialPagination';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const useStyles = makeStyles(theme => ({
	container: {
		backgroundColor: ({ backgroundColor }) =>
			backgroundColor && backgroundColor.length > 0
				? theme.palette.brand[backgroundColor[0].color]
				: '',
		padding: `${theme.sizes.componentSpacing}px 0`,
	},
	heading: {
		left: '-9999em',
		position: 'absolute',
	},
}));

const Testimonials = ({ blok }) => {
	const classes = useStyles(blok);
	const [activeIndex, setActiveIndex] = useState(0);

	return (
		<SbEditable content={blok}>
			<section className={classes.container}>
				<Typography variant="h1" className={classes.heading}>
					Testimonials
				</Typography>
				<Container>
					<AutoPlaySwipeableViews
						index={activeIndex}
						onChangeIndex={setActiveIndex}
						interval={blok.timeInterval * 1000}
					>
						{blok.testimonials &&
							blok.testimonials.map(testimonial => (
								<Testimonial
									testimonial={testimonial}
									key={testimonial._uid}
								/>
							))}
					</AutoPlaySwipeableViews>
					<NoSsr>
						{blok.testimonials.length > 1 ? (
							<TestimonialPagination
								activeIndex={activeIndex}
								numberOfSelectors={blok.testimonials.length}
								onSetActiveIndex={setActiveIndex}
							/>
						) : null}
					</NoSsr>
				</Container>
			</section>
		</SbEditable>
	);
};

export default Testimonials;
