import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

import StoryblokComponents from 'storyblok/';
import Footer from './Footer';
import Header from './Header';
import NewsletterSignup from './NewsletterSignup';
import SEO from './SEO';

import 'styles/base.css';
import 'styles/input.css';

import theme from 'theme';

const useStyles = makeStyles(theme => ({
	mainContent: {
		backgroundColor: theme.palette.common.white,
		display: 'block', // needed for IE11
		'& > *:first-child': {
			paddingTop: theme.sizes.headerHeight + theme.sizes.gutter,
		},
	},
}));

const MainContent = ({ children, elementType }) => {
	const classes = useStyles();

	switch (elementType) {
		case 'article':
			return (
				<article className={classes.mainContent}>{children}</article>
			);
		case 'main':
		default:
			return <main className={classes.mainContent}>{children}</main>;
	}
};

const PageWrapper = ({
	blok,
	description,
	displayNewsletterSignup,
	elementType,
	ogImage,
	postContent,
	preContent,
	title,
}) => {
	let meta = [];

	if (ogImage) {
		meta = [
			...meta,
			{
				property: 'og:image',
				content: ogImage.src,
			},
			{
				property: 'og:image:alt',
				content: ogImage.alt,
			},
			{
				property: 'og:image:height',
				content:
					ogImage.height || theme.sizes.socialSharingImage.height,
			},
			{
				property: 'og:image:width',
				content: ogImage.width || theme.sizes.socialSharingImage.width,
			},
		];
	}

	return (
		<>
			<SEO title={title} description={description} meta={meta} />
			<Header />
			<MainContent elementType={elementType}>
				{preContent}
				{blok &&
					blok.body &&
					blok.body.map(blok =>
						React.createElement(
							StoryblokComponents(blok.component),
							{
								key: blok._uid,
								blok: blok,
							}
						)
					)}
				{postContent}
			</MainContent>
			{displayNewsletterSignup ? <NewsletterSignup /> : null}
			<Footer />
		</>
	);
};

PageWrapper.propTypes = {
	description: PropTypes.string,
	displayNewsletterSignup: PropTypes.bool,
	elementType: PropTypes.oneOf(['main', 'article']),
	ogImage: PropTypes.shape({
		alt: PropTypes.string,
		height: PropTypes.number,
		src: PropTypes.string,
		width: PropTypes.number,
	}),
	title: PropTypes.string.isRequired,
};

PageWrapper.defaultProps = {
	displayNewsletterSignup: true,
	elementType: 'main',
};

export default PageWrapper;
