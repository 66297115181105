import React from 'react';
import SbEditable from 'storyblok-react';
import { Grid, makeStyles, Typography } from '@material-ui/core';

import { classifyAction } from 'services/link';

import Link from 'components/Link';

const useStyles = makeStyles(theme => ({
	description: {
		textAlign: 'center',
	},
	headline: {
		margin: theme.spacing(2, 0),
		textAlign: 'center',
	},
	image: {
		display: 'block',
		margin: '0 auto',
		width: 150,
	},
}));

const Feature = ({ blok }) => {
	const classes = useStyles();

	let Wrapper = ({ children }) => children;

	if (blok.linkTo && blok.linkTo.url) {
		const actionType = classifyAction(blok.linkTo.url);

		switch (actionType) {
			case 'absoluteUrl':
			case 'anchorUrl':
			case 'relativeUrl':
				Wrapper = ({ children }) => (
					<Link to={blok.linkTo.url}>{children}</Link>
				);
				break;
			default:
				Wrapper = ({ children }) => <>{children}</>;
		}
	}

	return (
		<SbEditable content={blok}>
			<Grid container justify="center" direction="column">
				<Wrapper>
					<img
						src={blok.image}
						alt={blok.headline}
						className={classes.image}
					/>
					<Typography
						variant="h4"
						component="h2"
						className={classes.headline}
					>
						{blok.headline}
					</Typography>
					{blok.description ? (
						<Typography
							component="p"
							className={classes.description}
						>
							{blok.description}
						</Typography>
					) : null}
				</Wrapper>
			</Grid>
		</SbEditable>
	);
};

export default Feature;
