import React from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';
import BackgroundImage from 'gatsby-background-image';
import { makeStyles, Typography } from '@material-ui/core';

import BlogPostPublishDate from 'components/BlogPostPublishDate';
import SocialSharing from 'components/SocialSharing';

import StoryblokComponents from 'storyblok/';

import { transform } from '@sporkbytes/storyblok-image';

const getInnerContainerHeight = theme => ({ height }) => {
	switch (height) {
		case 'short':
			return `calc(40vh - ${theme.sizes.headerHeight}px - ${theme.sizes.gutter}px)`;
		case 'medium':
			return `calc(70vh - ${theme.sizes.headerHeight}px - ${theme.sizes.gutter}px)`;
		case 'tall':
		default:
			return `calc(100vh - ${theme.sizes.headerHeight}px - ${theme.sizes.gutter}px)`;
	}
};

const getMobileBackgroundImageSettings = height => {
	let settings = {
		smartCrop: true,
		width: 400,
	};

	switch (height) {
		case 'short':
			settings.height = 400;
			break;
		case 'medium':
			settings.height = 600;
			break;
		case 'tall':
		default:
			settings.height = 800;
	}

	return settings;
};

const useStyles = makeStyles(theme => ({
	callsToActionContainer: {
		[theme.breakpoints.up('md')]: {
			alignItems: 'flex-end',
			display: 'flex',
			justifyContent: 'center',
		},
	},
	container: {
		backgroundImage: ({ backgroundImage, height }) =>
			`url(${transform(
				backgroundImage,
				getMobileBackgroundImageSettings(height)
			)})`,
		backgroundPosition: ({ anchorHorizontal, anchorVertical }) =>
			`${anchorHorizontal} ${anchorVertical}`,
		backgroundSize: 'cover',
		padding: `0 ${theme.sizes.gutter}px ${theme.sizes.gutter}px`,
		[theme.breakpoints.up('sm')]: {
			backgroundImage: ({ backgroundImage }) =>
				`url(${transform(backgroundImage, { smartCrop: true })})`,
			paddingBottom: 0,
		},
	},
	headlineContainer: {
		backgroundColor: 'rgba(0, 0, 0, 0.8)',
		color: theme.palette.common.white,
		margin: 'auto',
		maxWidth: 700,
		padding: `${theme.sizes.componentSpacing / 2}px ${
			theme.sizes.gutter
		}px`,
		textAlign: 'center',
		[theme.breakpoints.up('sm')]: {
			padding: `${theme.sizes.componentSpacing / 2}px ${
				theme.sizes.componentSpacing
			}px`,
		},
		[theme.breakpoints.up('md')]: {
			// need to use alignSelf instead of just margin to fix IE11 bug for alignment: https://github.com/philipwalton/flexbugs#flexbug-3
			alignSelf: ({ headlineAlignment }) => {
				switch (headlineAlignment) {
					case 'left':
						return 'flex-end';
					case 'center':
					default:
						return 'center';
				}
			},
			margin: ({ headlineAlignment }) => {
				switch (headlineAlignment) {
					case 'left':
						return `auto auto ${theme.sizes.componentSpacing}px ${theme.sizes.componentSpacing}px`;
					case 'center':
					default:
						return 'auto';
				}
			},
			textAlign: ({ headlineAlignment }) => headlineAlignment,
		},
	},
	headline: {
		textTransform: 'uppercase',
	},
	innerContainer: {
		display: 'flex',
		margin: '0 auto',
		maxWidth: theme.sizes.contentMaxWidth,
		minHeight: getInnerContainerHeight(theme),
		[theme.breakpoints.up('md')]: {
			height: getInnerContainerHeight(theme), // need to change from minHeight to height to fix IE11 bug for aligning headlineContainer: https://github.com/philipwalton/flexbugs#flexbug-3
		},
	},
	publishDate: {
		display: 'block',
		fontWeight: 700,
		marginBottom: theme.spacing(1),
		textTransform: 'uppercase',
	},
	socialLinksContainer: {
		fontWeight: 700,
		marginTop: theme.spacing(2),
	},
	subHeadline: {
		margin: theme.spacing(2, 0, 0),
	},
}));

const Hero = ({ additionalContent, blok }) => {
	const classes = useStyles(blok);
	const {
		headline,
		publishDate,
		showSocialSharingLinks,
		subHeadline,
		callsToAction,
	} = blok;

	const Wrapper = ({ children }) => {
		if (typeof blok.backgroundImage === 'object') {
			return (
				<BackgroundImage
					Tag="section"
					fluid={blok.backgroundImage}
					className={classes.container}
				>
					{children}
				</BackgroundImage>
			);
		} else {
			return <section className={classes.container}>{children}</section>;
		}
	};

	return (
		<SbEditable content={blok}>
			<Wrapper>
				<div className={classes.innerContainer}>
					<div className={classes.headlineContainer}>
						{publishDate && (
							<BlogPostPublishDate
								publishDate={publishDate}
								className={classes.publishDate}
							/>
						)}
						<Typography variant="h1" className={classes.headline}>
							{headline}
						</Typography>
						{subHeadline && (
							<Typography
								variant="h2"
								className={classes.subHeadline}
							>
								{subHeadline}
							</Typography>
						)}
						{additionalContent}
						{showSocialSharingLinks && (
							<div className={classes.socialLinksContainer}>
								<span>SHARE: </span>
								<SocialSharing headline={headline} />
							</div>
						)}
						{callsToAction && (
							<div className={classes.callsToActionContainer}>
								{callsToAction.map(blok =>
									React.createElement(
										StoryblokComponents(blok.component),
										{
											key: blok._uid,
											blok: blok,
										}
									)
								)}
							</div>
						)}
					</div>
				</div>
			</Wrapper>
		</SbEditable>
	);
};

Hero.propTypes = {
	additionalContent: PropTypes.element,
};

export default Hero;
