import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import MenuItemList from './MenuItemList';

const useStyles = makeStyles(theme => ({
	sectionName: {
		margin: '1em 0',
		textDecoration: 'underline',
	},
}));

const MenuSection = ({ name, items }) => {
	const classes = useStyles();

	return (
		<section>
			<Typography
				className={classes.sectionName}
				variant="h4"
				component="h2"
			>
				{name}
			</Typography>
			<MenuItemList items={items} />
		</section>
	);
};

MenuSection.propTypes = {
	name: PropTypes.string.isRequired,
	items: PropTypes.array.isRequired,
};

export default MenuSection;
