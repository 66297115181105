import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Chip } from '@sporkbytes/material-ui-kit-react';

const useStyles = makeStyles(theme => ({
	description: {
		margin: theme.spacing(1, 0),
		'& > p:last-child': {
			marginBottom: 0,
		},
	},
	item: {
		'& + li': {
			marginTop: theme.spacing(2),
		},
	},
}));

const MenuItem = ({ item: { description, friendlyName, DietaryTags } }) => {
	const classes = useStyles();

	return (
		<li className={classes.item}>
			<Typography variant="h5" component="h3">
				{friendlyName}
			</Typography>
			<Typography
				className={classes.description}
				dangerouslySetInnerHTML={{ __html: description }}
				component="div"
			/>
			{DietaryTags.map((tag, index) => (
				<Chip label={tag.name} key={index} />
			))}
		</li>
	);
};

MenuItem.propTypes = {
	item: PropTypes.shape({
		description: PropTypes.string,
		friendlyName: PropTypes.string.isRequired,
		DietaryTags: PropTypes.array.isRequired,
	}),
};

export default MenuItem;
