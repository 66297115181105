import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import MenuItem from './MenuItem';

const useStyles = makeStyles(theme => ({
	list: {
		listStyle: 'none',
		marginLeft: 0,
		padding: 0,
	},
}));

const MenuItemList = ({ items }) => {
	const classes = useStyles();

	return (
		<ul className={classes.list}>
			{items.map((item, index) => (
				<MenuItem item={item} key={index} />
			))}
		</ul>
	);
};

MenuItemList.propTypes = {
	items: PropTypes.array.isRequired,
};

export default MenuItemList;
