import React from 'react';
import SbEditable from 'storyblok-react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	container: {
		backgroundColor: ({ backgroundColor }) =>
			backgroundColor?.length > 0
				? theme.palette.brand[backgroundColor[0].color]
				: '',
	},
	innerContainer: {
		margin: '0 auto',
		maxWidth: theme.sizes.contentMaxWidth,
		padding: `${theme.sizes.componentSpacing / 2}px ${
			theme.sizes.gutter
		}px`,
		[theme.breakpoints.up('md')]: {
			padding: `${theme.sizes.componentSpacing}px ${theme.sizes.gutter}px`,
		},
	},
	videoContainer: {
		height: 0,
		paddingTop: '56.25%',
		position: 'relative',
	},
	video: {
		height: '100%',
		left: 0,
		position: 'absolute',
		top: 0,
		width: '100%',
	},
}));

const VideoEmbed = ({ blok }) => {
	const classes = useStyles(blok);

	return (
		<SbEditable content={blok}>
			<section className={classes.container}>
				<div className={classes.innerContainer}>
					<div className={classes.videoContainer}>
						{blok.videoId ? (
							<iframe
								src={`https://www.youtube.com/embed/${blok.videoId}`}
								frameBorder="0"
								allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen
								className={classes.video}
								title={`Embedded video ID of ${blok.videoId}`} // without this we get a console warning
							></iframe>
						) : null}
					</div>
				</div>
			</section>
		</SbEditable>
	);
};

export default VideoEmbed;
