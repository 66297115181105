import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Grid, makeStyles, NoSsr, Typography } from '@material-ui/core';
import { GridContainer, SingleSelect } from '@sporkbytes/material-ui-kit-react';
import qs from 'querystringify';

import Container from './Container';
import PartnerCard from './PartnerCard';
import PartnerTagContainer from './PartnerTagContainer';
import TextButton from './TextButton';

const FEATURED_PARTNERS_OVERLAP_AMOUNT = 225;

const useStyles = makeStyles(theme => ({
	featuredPartnersContainer: {
		marginBottom: theme.sizes.componentSpacing / 2,
		[theme.breakpoints.up('md')]: {
			marginBottom: theme.sizes.componentSpacing,
		},
		'&:first-child': {
			marginTop: -FEATURED_PARTNERS_OVERLAP_AMOUNT,
		},
	},
	featuredPartnerHeading: {
		marginBottom: theme.spacing(4),
		textAlign: 'center',
	},
	partnerTagSelectorContainer: {
		backgroundColor: 'rgba(255, 255, 255, 0.85)',
		boxShadow: theme.boxShadow,
		display: 'flex',
		flexDirection: 'column',
		padding: theme.sizes.gutter,
		[theme.breakpoints.up('md')]: {
			maxWidth: 720,
			padding: theme.sizes.componentSpacing,
		},
	},
	submitButton: {
		marginTop: theme.spacing(2),
	},
}));

const PartnerTagSelect = () => {
	const data = useStaticQuery(graphql`
		query GetPartnerTagsAndFeaturdPartners {
			allPartnerTag {
				nodes {
					id
					name
					backgroundColor
				}
			}
			allSporkLocation {
				nodes {
					id
					name
					slug
				}
			}
			allPartner(filter: { isFeaturedPartner: { eq: true } }) {
				nodes {
					id
					name
					description
					featuredIn
					isFeaturedPartner
					isNewPartner
					landingPageSlug
					localPhoto {
						childImageSharp {
							fluid(maxWidth: 400) {
								...GatsbyImageSharpFluid_tracedSVG
							}
						}
					}
					MealTypes {
						id
						name
					}
					PartnerTags {
						id
						name
					}
				}
			}
		}
	`);
	const featuredPartners = data.allPartner.nodes;
	const [partnerTag, setPartnerTag] = useState(
		data.allPartnerTag.nodes[
			Math.floor(Math.random() * data.allPartnerTag.nodes.length)
		]
	);
	const showLocation = data.allSporkLocation.nodes.length > 1;
	const [location, setLocation] = useState(
		showLocation ? data.allSporkLocation.nodes[0] : undefined
	);
	const hasFeaturedPartners = featuredPartners.length > 0;

	const classes = useStyles({ hasFeaturedPartners, partnerTag });

	return (
		<PartnerTagContainer
			marginTop={
				hasFeaturedPartners ? FEATURED_PARTNERS_OVERLAP_AMOUNT : null
			}
			partnerTag={partnerTag}
		>
			<Container>
				{hasFeaturedPartners
					? data.allSporkLocation.nodes.map(location => (
							<div className={classes.featuredPartnersContainer}>
								<Typography
									variant="h2"
									className={classes.featuredPartnerHeading}
								>
									Featured Restaurants
									{showLocation ? ` - ${location.name}` : ''}
								</Typography>
								<Grid container justify="center" spacing={2}>
									{featuredPartners
										.filter(
											({ featuredIn }) =>
												featuredIn === location.name
										)
										.map(featuredPartner => (
											<Grid
												key={featuredPartner.id}
												item
												xs={12}
												sm={6}
												md={3}
											>
												<PartnerCard
													partner={featuredPartner}
												/>
											</Grid>
										))}
								</Grid>
							</div>
					  ))
					: null}
				<NoSsr>
					<div className={classes.partnerTagSelectorContainer}>
						<Typography variant="h3" component="h2">
							We're looking for
						</Typography>
						<GridContainer>
							<SingleSelect
								options={data.allPartnerTag.nodes}
								getOptionLabel={({ name }) => name}
								getOptionKey={({ id }) => id}
								onChange={setPartnerTag}
								value={partnerTag}
								hideLabel={true}
								size="large"
								label="Cuisine"
							/>
						</GridContainer>
						{showLocation ? (
							<>
								<GridContainer>
									<Typography variant="h3" component="h2">
										in
									</Typography>
								</GridContainer>
								<GridContainer>
									<SingleSelect
										options={data.allSporkLocation.nodes}
										getOptionLabel={({ name }) => name}
										getOptionKey={({ id }) => id}
										onChange={setLocation}
										value={location}
										hideLabel={true}
										size="large"
										label="Location"
									/>
								</GridContainer>
							</>
						) : null}
						<TextButton
							action={`/the-restaurants/?${encodeURI(
								qs.stringify({
									location: location?.slug,
									tags: partnerTag.name,
								})
							)}`}
							className={classes.submitButton}
							text="Submit"
							title={`View ${partnerTag.name} restaurants`}
						/>
					</div>
				</NoSsr>
			</Container>
		</PartnerTagContainer>
	);
};

export default PartnerTagSelect;
