import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	innerContainer: {
		margin: '0 auto',
		maxWidth: theme.sizes.narrowContentMaxWidth,
		padding: `${theme.sizes.componentSpacing / 2}px ${
			theme.sizes.gutter
		}px`,
		[theme.breakpoints.up('sm')]: {
			padding: `${theme.sizes.componentSpacing}px ${theme.sizes.gutter}px`,
		},
	},
	introText: {
		backgroundColor: ({ backgroundColor }) =>
			theme.palette.brand[backgroundColor],
		fontSize: 24,
		fontWeight: 700,
		minHeight: 20,
	},
}));

const IntroText = ({ backgroundColor, children }) => {
	const classes = useStyles({ backgroundColor });

	return (
		<section className={classes.introText}>
			<div className={classes.innerContainer}>{children}</div>
		</section>
	);
};

IntroText.propTypes = {
	backgroundColor: PropTypes.oneOf([
		'brown',
		'gold',
		'orange',
		'pink',
		'salmon',
		'teal',
	]),
};

IntroText.defaultProps = {
	backgroundColor: 'gold',
};

export default IntroText;
