import React, { useState } from 'react';
import * as yup from 'yup';
import { createContact } from '@sporkbytes/api-client/PublicMarketing';
import {
	Alert,
	Form,
	GridContainer,
	LinkedTextInput,
} from '@sporkbytes/material-ui-kit-react';

import FormContainer from './FormContainer';
import PrimaryButton from './PrimaryButton';
import SectionHeadline from './SectionHeadline';
import withRecaptcha from './withRecaptcha';

import axios from 'services/axios';

const defaultState = { state: 'waiting', message: null };

const NewsletterSignup = () => {
	const [state, setState] = useState(defaultState);
	const validationSchema = yup.object().shape({
		emailAddress: yup
			.string()
			.email('Please enter a valid email address.')
			.required('Please enter your email address.'),
	});

	const Heading = () => (
		<SectionHeadline>Sign up to get updates</SectionHeadline>
	);
	const SignupForm = withRecaptcha(({ getRecaptchaToken }) => (
		<Form
			onSubmit={async values => {
				try {
					const recaptchaResponse = await getRecaptchaToken();

					const message = await createContact(axios, {
						...values,
						recaptchaResponse,
					});

					setState({ state: 'success', message });
				} catch (error) {
					const message =
						error.response?.data?.message ??
						'Error submitting form. Please try again.';

					setState({ state: 'error', message });
				}
			}}
			onCancel={null}
			submitButtonContent={'Join'}
			validationSchema={validationSchema}
		>
			{() => (
				<>
					<Heading />
					<GridContainer columns={3}>
						<LinkedTextInput
							label="Enter your email"
							type="email"
							name="emailAddress"
						/>
					</GridContainer>
				</>
			)}
		</Form>
	));

	if (state.state === 'error') {
		return (
			<FormContainer>
				<Heading />
				<Alert type="error">{state.message}</Alert>
				<PrimaryButton onClick={() => setState(defaultState)}>
					Try Again
				</PrimaryButton>
			</FormContainer>
		);
	}

	if (state.state === 'success') {
		return (
			<FormContainer>
				<Heading />
				<Alert type="success">{state.message}</Alert>
			</FormContainer>
		);
	}

	return (
		<FormContainer>
			<SignupForm />
		</FormContainer>
	);
};

export default NewsletterSignup;
